import React, { useEffect, useState, useRef } from 'react';
// import "~bootstrap-icons/font/bootstrap-icons";
import "../../css/AISearch.css"
import api from "../../api"
import BeatLoader from "react-spinners/BeatLoader";
import newChatIcon from "../../../src/img/ai-search/newChatIcon.svg"

import techSagarIcon from "../../../src/img/ai-search/tech_sagar_1.png"
import Blockchain from '../../../src/img/ai-search/Blockchain.svg'
import Robotics_And_Automation from '../../../src/img/ai-search/Robotics_And_Automation.svg'
import Computer_Vision from '../../../src/img/ai-search/Computer_Vision.svg'
import Data_Science from '../../../src/img/ai-search/Data_Science.svg'
import AISearchVerify from './AISearchVerify';
import Cards from "./Cards";
import { toast } from "react-toastify";

import userIcon from "../../../src/img/ai-search/userIcon.png"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { NoiseAwareSharp } from '@mui/icons-material';

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import copy from 'copy-text-to-clipboard';
import feedImg from "../../../src/img/feed.jpg"
import ReactMarkdown from 'react-markdown';


import Slider from "react-slick";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { LiaAngleRightSolid } from "react-icons/lia";




const AISearch = () => {
    const [userInput, setUserInput] = useState("")
    const [editUserInput, setEditUserInput] = useState("")
    const [userChats, setUserChats] = useState([]);
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const [serverStatus, setServerStatus] = useState(false)
    const suggestedQues = ["Robotics and automation", "Javascript Dom", "Blockchain", "Explain about data science"]
    const [onFocusStatus, setOnFocusStatus] = useState(false)
    const [editPromptArrStatus, setEditPromptArrStatus] = useState([])
    const [copyClipboardArrStatus, setCopyClipboardArrStatus] = useState([])
    const [copyClipboardPromptArrStatus, setCopyClipboardPromptArrStatus] = useState([])
    // State to track hover
    const [userPromptIsHovered, setUserPromptIsHovered] = useState([]);
    const [hoveredCard, setHoveredCard] = useState(false);
    const [ragToken, setRagToken] = useState("");
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    const [restartMessage, setRestartMessage] = useState("");
    const [isVerifiedModal, setIsVerifiedModal] = useState(false)

    const [countMsg, setCountMsg] = useState(0)
    const [promptLimit, setPromptLimit] = useState(20);
    
    useEffect(() => {
        const fetchRagToken = async () => {
            try {
                const res = await fetch(api.RagToken);
                const data = await res.json();
                if (res.ok) {
                    setRagToken(data.data);
                } else {
                    toast.error("Failed to fetch authorization token");
                    console.error('Error fetching token:', data.message);
                }
            } catch (error) {
                toast.error("Error fetching authorization token");
            }
        };
        fetchRagToken();
    }, []);

    // Edit Button
    useEffect(() => {
        if(editPromptArrStatus.includes(true)){
            const index = editPromptArrStatus.indexOf(true);
            const userPrompt = userChats[index]["user"]["message"]
            setEditUserInput(userPrompt)
        }

    }, [editPromptArrStatus])


    const textareaRef = useRef(null);
    const handleEditInputChange = (event) => {
        setEditUserInput(event.target.value);
    };

    useEffect(() => {
        if (textareaRef.current) {
        // Reset height to 'auto' to correctly calculate the new scrollHeight
        textareaRef.current.style.height = 'auto';
        // Set the height based on the scrollHeight
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [editUserInput]);


    // Scroll-bar
    const msgEnd = useRef(null)

    const copyClipboardHandler = async (index, boolVal, content, flag) => {
            
        // navigator.clipboard.writeText(content)

        if(flag === "response"){
            setCopyClipboardArrStatus(prevState => {
                const newState = [...prevState]
                newState[index] = boolVal
                return newState
            })

            setTimeout(() => {
                setCopyClipboardArrStatus((prevState) => {
                    const newState = [...prevState]
                    newState[index] = false
                    return newState
                })
            }, 3000)
        }

        if(flag === "prompt"){
            setCopyClipboardPromptArrStatus(prevState => {
                const newState = [...prevState]
                newState[index] = boolVal
                return newState
            })

            setTimeout(() => {
                setCopyClipboardPromptArrStatus((prevState) => {
                    const newState = [...prevState]
                    newState[index] = false
                    return newState
                })
            }, 3000)
        }

        // try {
        //     await navigator.clipboard.writeText(content);
        // } 
        // catch (err) {
        //     console.error('Failed to copy text: ', err);
        // }

        // Copy to clipboard
        copy(content)
        

        
    }



    const editCancelClickHandler = (index, boolVal, flag) => {
        if(editPromptArrStatus.includes(true) && flag==="editClick"){
            toast.warning("Please complete previous edit!!");
            return
        }
        setEditPromptArrStatus(prevStatus => {
            const newStatus = [...prevStatus];
            newStatus[index] = boolVal
            return newStatus;
        });

        if(!boolVal){
            setEditUserInput("")
        }
    }

    const userPromptHoverHandler = (index, boolVal) => {
        // if(!boolVal){
        //     editCancelClickHandler(index, false)
        // }
        setUserPromptIsHovered(prevStatus => {
            const newStatus = [...prevStatus];
            newStatus[index] = boolVal
            return newStatus;
          });
    }

    const [cardsValue, setCardsValue] = useState([
        {
            "title" :"Look for Indian companies working in Mixed Reality.",
            "icon" :  Blockchain,
            "trending": false
        },
        {
            "title" :"List India-based researchers in Quantum Optics.",
            "icon" :  Computer_Vision,
            "trending": false
        },
        {
            "title" :"Look for the offerings of Log 9 Materials.",
            "icon" :   Data_Science,
            "trending": false
        },
        {
            "title" :"Find Indian companies that manufacture data connectors.",
            "icon" :  Robotics_And_Automation,
            "trending": true
        },
        {
            "title" :"Provide the list of indian researchers those who working on blockchain and also provide the products.",
            "icon" :  Robotics_And_Automation,
            "trending": false
        },
    ])


        const [cardData, setCardData] = useState([
            [
                {
                    "title" :"Look for Indian companies working in Mixed Reality.",
                    "icon" :  Blockchain,
                    "trending": false
                },
                {
                    "title" :"List India-based researchers in Quantum Optics.",
                    "icon" :  Computer_Vision,
                    "trending": false
                },
                {
                    "title" :"Look for the offerings of Log 9 Materials.",
                    "icon" :   Data_Science,
                    "trending": false
                },
                {
                    "title" :"Find Indian companies that manufacture data connectors.",
                    "icon" :  Robotics_And_Automation,
                    "trending": true
                },
            ],
            [
                {
                    "title" :"Provide the list of indian researchers those who working on blockchain and laso provide the products.",
                    "icon" :  Robotics_And_Automation,
                    "trending": false
                },
            ]
        ])

        // Card Responsiveness
        function useWindowSize() {
            const [windowSize, setWindowSize] = useState({
              width: undefined,
              height: undefined,
            });
          
            useEffect(() => {
              function handleResize() {
                setWindowSize({
                  width: window.innerWidth,
                  height: window.innerHeight,
                });
              }
          
              window.addEventListener('resize', handleResize);
              handleResize();
          
              return () => window.removeEventListener('resize', handleResize);
            }, []);
          
            return windowSize;
        }
    
        const size = useWindowSize();
        
        useEffect(() => {
            // xs (1)
            if(size.width >0 && size.width <= 576){
                const new_arr = [
                    cardsValue.slice(0, 1), 
                    cardsValue.slice(1, 2),
                    cardsValue.slice(2, 3),
                    cardsValue.slice(3, 4),
                    cardsValue.slice(4, 5)                    
                ]
                setCardData(new_arr)
            }
            // sm (2)
            else if(size.width >576 && size.width <= 768){
                const new_arr = [
                    cardsValue.slice(0, 2), 
                    cardsValue.slice(2, 4),
                    cardsValue.slice(4)          
                ]
                setCardData(new_arr)
            }
            // md (3)
            else if(size.width >768 && size.width <= 992){
                const new_arr = [
                    cardsValue.slice(0, 3), 
                    cardsValue.slice(3)          
                ]
                setCardData(new_arr)
            }
            // lg & above (4)
            else {
                const new_arr = [
                    cardsValue.slice(0, 4), 
                    cardsValue.slice(4)          
                ]
                setCardData(new_arr)
            }
        }, [size.width])
        
    


    const editSendButtonClickHandler = async (index) => {
        // If user already asked query then wait for result
        if(waitingForResponse){
            toast.warning("Please wait");
            return
        }

        if(editUserInput.length === 0){
            toast.warning("Please input value");
            return
        }



        // Update Status (calling function)
        editCancelClickHandler(index, false)
        userPromptHoverHandler(index, false)

        const input_val = editUserInput
        setEditUserInput("")

        // Slice the array upto  edited prompt
        setUserChats(prevState => {
            const updated = { user: { message: input_val }, bot: { message: "Loading" } }
            

            return [
                ...prevState.slice(0, index),
                updated
            ];
        });

        
        const chat_response = await getChatResponse(input_val);
        // let chat_response = "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.";
        if(userChats.length-1 >= index){
            setUserChats((prevVal) => {
                const lastChatIndex = prevVal.length - 1;
                const lastChat = prevVal[lastChatIndex];
                const updatedChat = {
                    ...lastChat,
                    bot: { message: chat_response }
                };
                return [
                    ...prevVal.slice(0, lastChatIndex), // Keep all previous chats unchanged
                    updatedChat // Update the last chat with the bot response
                ];
            });
        }
    }

    // console.log("val: ", countMsg)
    // console.log(userChats.length)

    useEffect(() => {
        setCountMsg(userChats.length)
    }, [userChats])

    const sendButtonClickHandler = async(input=null) => {
        // setCountMsg(countMsg + 1)
        if(countMsg === promptLimit){
            setRestartMessage("Prompt limit reached. Please restart.");
            return
        }

        if(countMsg === 10){
            setIsVerifyModalOpen(true);
            setCountMsg(countMsg+1)
            return
        }

        if(countMsg>=10 && countMsg<=20 && !isVerifiedModal){
            setIsVerifyModalOpen(true);
            return 
        }

        // If user already asked query then wait for result
        if(waitingForResponse){
            toast.error("Please wait");
            return
        }

        let user_input = ""
        if(input === null){
            user_input = userInput.trim()
            setUserInput("")
        }
        else{
            user_input = input
        }


        if (user_input.length > 0) {
            setUserChats((prevVal) =>
                [...prevVal,
                { user: { message: user_input }, bot: { message: "Loading" } }
                ]
            );
            
            const chat_response = await getChatResponse(user_input);
            // let chat_response = "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.";
            
            setUserChats((prevVal) => {
                const lastChatIndex = prevVal.length - 1;
                const lastChat = prevVal[lastChatIndex];
                let updatedChat = {}
                if (chat_response.length === 0) {
                    updatedChat = {
                        ...lastChat,
                        bot: { message: chat_response }
                    };
                } else {
                    updatedChat = {
                        ...lastChat,
                        bot: { message: chat_response }
                    };
                }
                return [
                    ...prevVal.slice(0, lastChatIndex), // Keep all previous chats unchanged
                    updatedChat // Update the last chat with the bot response
                ];
            });
            

        }
        else{
            toast.warning("Please input value");
            return
        }
    }
    

    const getChatResponse = async (input_str) => {
        setWaitingForResponse(true)
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("rag-token", ragToken);

            const raw = JSON.stringify({
                "message": input_str
              });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            //   const res = await fetch(api.AISearchChat, requestOptions);
              const res = await fetch(api.AI_CHAT, requestOptions);
              
              const data = await res.json();

              if(res.ok){
                setWaitingForResponse(false)
                if (data.status === 400){
                    if (data.message === "Prompt limit reached. Please restart.") {
                        // setRestartMessage("Prompt limit reached. Please restart.");
                        return ""
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 3000);
                    } else {
                        // setIsVerifyModalOpen(true);
                        setWaitingForResponse(false)
                        return ""
                    }
                }
                if(data.data){
                    return data.data
                }
                // return "Something went wrong"
              }
              setWaitingForResponse(false)
            //   return "Something went wrong"
        } 
        catch (err) {
            setWaitingForResponse(false)
            return "Something went wrong"
        }
    }

    const suggestedQuesHandler = (input_ques) => {
        setUserInput(input_ques.title)
        sendButtonClickHandler()
    }

    const handleEnter = async (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            // Prevent the default behavior of Enter key
            event.preventDefault();
            // Update the textarea value to include a new line
            setUserInput((prevText) => prevText + '\n');
        }
        else if (event.key === 'Enter') {
            sendButtonClickHandler()
        }
    }


    const newChatClickHandler = () => {
        if(waitingForResponse){
            toast.error("Please wait");
            return
        }
        setUserChats([])
        setShowIcon(false)
    }

    const convertNewLinesToBreaks = (text) => {
        return text.replace(/\n/g, '<br />');
      };


    // For Scrollbar - redirecting user to last message
    useEffect(() => {
        if(userChats.length>0 && msgEnd !== null){
            msgEnd.current.scrollIntoView()
        }
    }, [userChats])

    const scrollToBottomClickHandler = () => {
        // if(userChats.length>0 && msgEnd !== null){
        //     msgEnd.current.scrollIntoView()
        // }

        const container = document.querySelector('.ai__search__chats__container');
        container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
    }



    // Showing Icon on scrolling upwards
    const [showIcon, setShowIcon] = useState(false);

    useEffect(() => {
        if (userChats.length > 0) {
            const container = document.querySelector('.ai__search__chats__container');
            let lastScrollTop = 0;

            const handleScroll = () => {
                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight;
                const clientHeight = container.clientHeight + 10;
                const isScrollingUp = scrollTop < lastScrollTop;

                // If scrolling up and not at the bottom, show the icon
                if (isScrollingUp && scrollTop + clientHeight < scrollHeight) {
                    setShowIcon(true);
                } 
                else if (scrollTop + clientHeight < scrollHeight) {
                    // If scrolling down but not at the bottom, show the icon
                    setShowIcon(true);
                } 
                else {
                    // At the bottom, hide the icon
                    setShowIcon(false);
                }

                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrollTop
            };

            container.addEventListener('scroll', handleScroll);

            // Cleanup event listener on component unmount
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [userChats]);


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: React.cloneElement(<LiaAngleRightSolid size={40} color={hoveredCard ? "#2e5081" : "white"}/>, {
            className: 'slick-next',
            onMouseEnter: () => setHoveredCard(true),
            onMouseLeave: () => setHoveredCard(false),
        }),
        prevArrow: React.cloneElement(<LiaAngleLeftSolid size={40} color={hoveredCard ? "#2e5081" : "white"}/>, {
            className: 'slick-prev',
            onMouseEnter: () => setHoveredCard(true),
            onMouseLeave: () => setHoveredCard(false),
        }),
        responsive: [
            // max-width
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1
                }
            },
            
          ]
      };



  
    



return (
    <div className='ai__search__main__container'>

            <div className='ai__search__chat__box'>

            {
                userChats.length === 0 ?
                    <div  className="ai__search__chat__box__container">
                        <div className="top_box">
                            <img src={techSagarIcon} alt="" />
                            {/* <h4 className="suggestion_title">
                            Techsagar AI-Powered Search
                            </h4> */}
                        </div>

                        <div className="suggestion_title">
                            Techsagar AI-Powered Search
                        </div>

                        {/* <div className="bottom__box__container">
                            {
                                cardData.length>0 &&
                                <Carousel
                                    autoPlay={false}
                                    animation="fade"
                                    navButtonsAlwaysVisible={true}
                                    indicators={false}
                                    cycleNavigation={true}
                                    interval={0}
                                    timeout={0}
                                    duration={0}
                                >
                                    {
                                        cardData.map((dataArr, i) => (
                                            <div className='bottom__boxes' key={i}>
                                                {
                                                    dataArr.map((ques, i) => (
                                                        <div key={i}  onClick={() => {sendButtonClickHandler(ques["title"])}} style={{cursor:"pointer", margin:"10px 0",width:"100%"}}>
                                                            <Cards text={ques}/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                
                                </Carousel>
                            
                            }
                        </div> */}

                        {/* <div className="bottom__box__container">

                        <div style={{width: "100%"}} uk-slider="autoplay:false; finite:false">
                            <div className="uk-position-relative">
                                <div className="uk-slider-container uk-light">
                                <div className="uk-slider-items d-flex justify-content-around pt-2 pb-2 g-2 uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-4@xl">
                                    {
                                        cardsValue.map((ques, i) => (
                                            <div key={i}  onClick={() => {sendButtonClickHandler(ques["title"])}} 
                                            className='col-lg-3 col-md-4 col-sm-6 col-12'
                                            onMouseEnter={() => setHoveredCard(true)}
                                            onMouseLeave={() => setHoveredCard(false)}
                                            style={{ paddingLeft: '6px', paddingRight: '7px'}}
                                            >
                                                <Cards text={ques}/>
                                            </div>
                                        ))
                                    }
                                    
                                </div>
                                </div>
                                <a style={{left: "12px", display: "flex", alignItems: "center", justifyContent: "center"}} 
                                onMouseEnter={() => setHoveredCard(true)}
                                onMouseLeave={() => setHoveredCard(false)}
                                className="uk-position-center-left uk-position-small uk-hidden-hover" href uk-slidenav-previous uk-slider-item="previous">
                                    <LiaAngleLeftSolid color={hoveredCard ? "#2e5081" : "white"} size={35}/>
                                </a>
                                <a style={{right: "12px", display: "flex", alignItems: "center", justifyContent: "center"}}  
                                onMouseEnter={() => setHoveredCard(true)}
                                onMouseLeave={() => setHoveredCard(false)}
                                className="uk-position-center-right uk-position-small uk-hidden-hover" href uk-slidenav-next uk-slider-item="next">
                                    <LiaAngleRightSolid color={hoveredCard ? "#2e5081" : "white"} size={35}/>
                                </a>
                            </div>
                        </div>

                        </div> */}



                        <div className="bottom__box__container">

                            <Slider {...settings}>
                            {
                                cardsValue.map((ques, i) => (
                                    <div key={i}  onClick={() => {sendButtonClickHandler(ques["title"])}} 
                                        className="card__parent"
                                        onMouseEnter={() => setHoveredCard(true)}
                                        onMouseLeave={() => setHoveredCard(false)}
                                    >
                                        <Cards text={ques}/>
                                    </div>
                                ))
                            }
                            </Slider>
                        
                        </div>

                    </div>

                    :
                    <div className="ai__search__chats__container">
                    

                        <div className="ai__search__chats__container__box">
                            {
                                userChats.map((chats, i) => (
                                    <div className='ai__search__chats__box' key={i}>
                                        {
                                            chats.hasOwnProperty("user") &&
                                            <>
                                                <div 
                                                    className='ai__search__user__chat'
                                                    onMouseEnter={() => userPromptHoverHandler(i, true)}
                                                    onMouseLeave={() => userPromptHoverHandler(i, false)} 
                                                >
                                                    <img className="ai__search__icon" src={userIcon} alt="user" style={{width:"40px"}}/>

                                                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", width: "100%", gap: "15px"}}>
                                                        <div className="ai__search__user__query" style={{ whiteSpace: 'pre-wrap' }}
                                                        // style={{width: editPromptArrStatus[i] === true ? "100%" : null}}
                                                        dangerouslySetInnerHTML={{ __html: convertNewLinesToBreaks(chats.user.message) }}
                                                        >
                                                            {/* {
                                                                editPromptArrStatus[i] === true ?

                                                                <textarea
                                                                ref={textareaRef}
                                                                value={editUserInput}
                                                                onChange={handleEditInputChange}
                                                                className="ai__search__user__query__textarea"
                                                                rows={1} 
                                                                />
                                                                : */}
                                                                {/* {chats.user.message} */}
                                                                
                                                                
                                                            {/* } */}
                                                        </div>

                                                        {
                                                            // (userPromptIsHovered[i] || editPromptArrStatus[i]===true) &&
                                                            <div className='ai__search__user__query__edit'>
                                                                {/* {
                                                                    (editPromptArrStatus[i]===undefined || editPromptArrStatus[i]===false) ? */}

                                                                    {
                                                                        userPromptIsHovered[i] &&
                                                                        <>
                                                                            <button onClick={() => copyClipboardHandler(i, true, chats.user.message, "prompt")} className='ai__search__copy__btn'>
                                                                                {
                                                                                    copyClipboardPromptArrStatus[i] ? 
                                                                                    <DoneIcon fontSize="large"/> 
                                                                                    : 
                                                                                    <ContentCopyIcon fontSize="large"/>
                                                                                }
                                                                            </button>

                                                                            <button onClick={() => setUserInput(chats.user.message)}>
                                                                                <EditIcon fontSize="large"/>
                                                                            </button>

                                                                        </>
                                                                    
                                                                }
                                                                <span>{i+1}/{promptLimit}</span>

                                                                    
                                                                    
{/* 
                                                                    :

                                                                    <div className='ai__search__user__query__save__btn'>
                                                                        <button onClick={() => editCancelClickHandler(i, false)} className='ai__search__user__query__cancel'>Cancel</button>
                                                                        <button onClick={() => editSendButtonClickHandler(i)} className='ai__search__user__query__send'>Send</button>
                                                                    </div>
                                                                } */}
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </>
                                        }
                                        {
                                            chats.hasOwnProperty("bot") &&
                                            chats.bot.message === "Loading" ?
                                            <BeatLoader color="#706b69" speedMultiplier={0.7} />
                                            :
                                            chats.bot.message.length > 0 && 
                                            <div>
                                                <div className='ai__search__bot__chat'>
                                                    <img className="ai__search__icon" src={techSagarIcon} alt="bot" style={{width:"40px"}}/>
                                                    <div className="ai__search__bot__query">
                                                        <ReactMarkdown style={{textWrap: "pretty"}}>{chats.bot.message}</ReactMarkdown>
                                                    </div>
                                                </div>
                                                <button onClick={() => copyClipboardHandler(i, true, chats.bot.message, "response")} className='ai__search__copy__btn'>
                                                    {
                                                        copyClipboardArrStatus[i] ? 
                                                        <DoneIcon fontSize="large"/> 
                                                        : 
                                                        <ContentCopyIcon fontSize="large"/>
                                                    }
                                                </button>
                                            </div>
                                        }

                                    </div>
                                ))
                            }

                            <div ref={msgEnd}></div>
                        </div>
                        
                        {showIcon &&
                        <div onClick={() => scrollToBottomClickHandler()}
                        className={`scrollDown toggle ${showIcon ? 'show' : 'hide'}`} id="scroll_down"
                        >
                            <i className="bi bi-arrow-down-circle"></i>
                            {/* <i class="fa-regular fa-paper-plane"></i> */}
                        </div>
                        }

                    </div>
            }




        </div>
        {
            restartMessage ? 
                <div className="restart-message">
                    {restartMessage}
                </div>
                :
                <>
        <div className='ai__search__searchbar__box'>
            <div onClick={newChatClickHandler} className='ai__search__searchbar__box__elem1'>
                <img src={newChatIcon} alt="new-chat" />
            </div>

            <div className="outer_wrapper">
                <div className='ai__search__searchbar__box__elem2'>
                    <textarea id="user_input" value={userInput}
                        onChange={e => setUserInput(e.target.value)}
                        placeholder="Write your search message"
                        rows="2" cols="40" 
                        onKeyDown={handleEnter} onKeyPress={e => { if (e.key === 'Enter') e.preventDefault() }}
                        onFocus={() => setOnFocusStatus(true)}
                        onBlur={() => setOnFocusStatus(false)}
                    />
                    <button className="ai__search__send__btn"
                        onClick={() => sendButtonClickHandler()}
                        style={{cursor: waitingForResponse ? "no-drop" : "pointer"}}
                    >
                        <i 
                        style={{color: !onFocusStatus ? "lightgrey" : "#746e6e"}}
                        className="bi bi-send" id="submit_button_icon"></i>
                    </button>
                </div>
            </div>

        </div>
        <div style={{display: 'flex'}}>
        <div className='ai__search__footer__terms'>
        AI-powered search functionality to provide relevant information. While we strive for accuracy, AI technology may occasionally make mistakes. We recommend that you double-check any critical information before using it. For more information, please read our <a style={{color: '#426CFF'}} href="/terms_of_service">
                        Terms of Service
                    </a>
                    </div>
                    </div>
        <AISearchVerify
                isOpen={isVerifyModalOpen}
                onClose={() => setIsVerifyModalOpen(false)} // Close the OTP modal
                ragToken={ragToken} // Pass ragToken to the modal
                setIsVerifiedModal = {setIsVerifiedModal}
                setPromptLimit={setPromptLimit}  // Pass this function to update promptLimit
            />
             </>
        }
    </div >
);
}


export default AISearch;