export default {
    login: `${process.env.REACT_APP_API_URL}/api/consumer_login/`,
    consumer_user_types: `${
        process.env.REACT_APP_API_URL
    }/api/consumer_user_types_list/`,
    list_of_cities: `${process.env.REACT_APP_API_URL}/api/list_of_cities/`,
    list_of_countries: `${
        process.env.REACT_APP_API_URL
    }/api/list_of_countries/`,
    send_otp: `${process.env.REACT_APP_API_URL}/api/send_otp/`,
    consumer_signup: `${process.env.REACT_APP_API_URL}/api/consumer_signup/`,
    filter_search: `${process.env.REACT_APP_API_URL}/consumer/filter/`,
    tech_areas: `${process.env.REACT_APP_API_URL}/bulletin/technology/`,
    company_types: `${process.env.REACT_APP_API_URL}/bulletin/company/type/`,
    company_sizes: `${process.env.REACT_APP_API_URL}/bulletin/company/size/`,
    sectors_served: `${process.env.REACT_APP_API_URL}/bulletin/sectors/`,
    geographies_served: `${process.env.REACT_APP_API_URL}/bulletin/geography/`,
    
    company_categories: `${process.env.REACT_APP_API_URL}/bulletin/company_categories/`,
    application_areas: `${process.env.REACT_APP_API_URL}/bulletin/application_areas/`,
    verifyEmail: `${process.env.REACT_APP_API_URL}/api/verify_email/`,
    get_suggestions: `${process.env.REACT_APP_API_URL}/consumer/get_suggestions/`,
    get_suggestion_product: `${process.env.REACT_APP_API_URL}/technology/get_suggestion_product/`,
    products_search: `${process.env.REACT_APP_API_URL}/technology/filter_products/`,
    tech_area_browsing: `${process.env.REACT_APP_API_URL}/bulletin/techarea/browsing/`,
    trending_products: `${process.env.REACT_APP_API_URL}/bulletin/trending_products/`,
    entities_locations: `${process.env.REACT_APP_API_URL}/bulletin/get_entities_locations/`,
    template_detail: `${process.env.REACT_APP_API_URL}/api/ra/template/detail/`,
    support_and_feedback: `${process.env.REACT_APP_API_URL}/technology/support_and_feedback/`,
    forgot_password: `${process.env.REACT_APP_API_URL}/api/forgot_password/`,
    academia_researchers_list: `${process.env.REACT_APP_API_URL}/technology/academia_researchers_list/`,
    change_user_password: `${process.env.REACT_APP_API_URL}/api/change_user_password/`,
    get_tech_capabilities: `${process.env.REACT_APP_API_URL}/bulletin/technology_capability_list/`,
    tech_area_search: `${process.env.REACT_APP_API_URL}/technology/search_tech/`,
    verify_otp: `${process.env.REACT_APP_API_URL}/api/verify_otp/`,
    consumer_action_on_entity: `${process.env.REACT_APP_API_URL}/api/consumer_action_on_company/`,
    action_on_technology: `${process.env.REACT_APP_API_URL}/api/action_on_technology/`,
    get_map_region_count: `${process.env.REACT_APP_API_URL}/technology/get_region_count/`,
    techarea_financial_info: `${process.env.REACT_APP_API_URL}/technology/tech_area_financial_info/`,
    companyFileUpload: `${process.env.REACT_APP_API_URL}/api/upload_company_files/`,
    getFollowedEntities : `${process.env.REACT_APP_API_URL}/technology/entity_following_dashboard_page/`,
    entityLookup: `${process.env.REACT_APP_API_URL}/api/ra/search/company_lookup/`,
    claimEntity: `${process.env.REACT_APP_API_URL}/api/claim_entity/`,
    feature_yourself: `${process.env.REACT_APP_API_URL}/api/feature_yourself/`,
    // "http://127.0.0.1:8000/api/feature_yourself/",
    // `${process.env.REACT_APP_API_URL}/api/feature_yourself/`,
    getCreditMetrics: `${process.env.REACT_APP_API_URL}/payments/get_credit_metrics/`,
    getEntityCreditMetrics: `${process.env.REACT_APP_API_URL}/payments/get_entity_credit_metrics/`,
    getCredits: `${process.env.REACT_APP_API_URL}/payments/get_credits/`,
    changeProfile: `${process.env.REACT_APP_API_URL}/technology/edit_profile_page/`,
    changePassword: `${process.env.REACT_APP_API_URL}/api/change_user_password_from_profile/`,
    buy_credits: `${process.env.REACT_APP_API_URL}/payments/buy_credits/`,
    createHash: `${process.env.REACT_APP_API_URL}/payments/create_hash/`,
    getPayuURL: `${process.env.REACT_APP_API_URL}/payments/get_payu_url/`,
    editNotificationSettings: `${process.env.REACT_APP_API_URL}/technology/edit_notifications_settings/`,
    getProfilePage: `${process.env.REACT_APP_API_URL}/technology/get_profile_page/`,
    getNotifications: `${process.env.REACT_APP_API_URL}/technology/get_notifications/`,
    checkEmailAvailability: `${process.env.REACT_APP_API_URL}/api/check_email_availability/`,
    checkPasswordValidation: `${process.env.REACT_APP_API_URL}/api/check_password_validation/}`,
    checkNumberAvailability: `${process.env.REACT_APP_API_URL}/api/check_number_availability/`,
    getTransaction: `${process.env.REACT_APP_API_URL}/payments/get_transaction/`,
    verifyToken: `${process.env.REACT_APP_API_URL}/api/verify_token/`,
    fetchReports: `${process.env.REACT_APP_API_URL}/technology/fetch_reports/`,
    validateResetRequest:  `${process.env.REACT_APP_API_URL}/api/validate_user_password_change_request/`,
    secretFeatureSubmit: `${process.env.REACT_APP_API_URL}/api/feature_yourself_pilot/`,
    transactionsReport: `${process.env.REACT_APP_API_URL}/payments/transactions_report/`,
    fetchWhitepapers: `${process.env.REACT_APP_API_URL}/technology/fetch_whitepapers/`,
    uploadWhitepapers: `${process.env.REACT_APP_API_URL}/cms/upload_whitepapers/`,
    consumerLogout: `${process.env.REACT_APP_API_URL}/api/consumer_logout/`,
    eventsWebinar : `${process.env.REACT_APP_API_URL}/technology/fetch_events_webinars/`,
    summary : `${process.env.REACT_APP_API_URL}/technology/fetch_summary_docs/`,
    signalNews : `${process.env.REACT_APP_API_URL}/technology/fetch_signal_and_news/`,
    reports : `${process.env.REACT_APP_API_URL}/technology/fetch_reports/`,
    caseStudy : `${process.env.REACT_APP_API_URL}/technology/fetch_case_study/`,
    spotlight : `${process.env.REACT_APP_API_URL}/technology/fetch_spotlight/`,
    policyUpdates : `${process.env.REACT_APP_API_URL}/technology/fetch_policy_updates/`,
    contributorDetails : `${process.env.REACT_APP_API_URL}/technology/fetch_contributor_details/`,
    followers :`${process.env.REACT_APP_API_URL}/technology/entity_follower_dashboard_page/`,
    latestFeed :`${process.env.REACT_APP_API_URL}/technology/fetch_latest_feed/`,
    allEvent:`${process.env.REACT_APP_API_URL}/technology/fetch_events_webinars/`,
    signalNewsApi:`${process.env.REACT_APP_API_URL}/technology/fetch_signal_and_news/`,
    articleApi:`${process.env.REACT_APP_API_URL}/technology/fetch_articles/`,
    recentlyAddApi:`${process.env.REACT_APP_API_URL}/bulletin/fetch_recently_added/`,
    entitySummaryApi:`${process.env.REACT_APP_API_URL}/bulletin/fetch_entity_data/`,
    caseStudyApi:`${process.env.REACT_APP_API_URL}/technology/fetch_case_study/`,
    contributorApi:`${process.env.REACT_APP_API_URL}/technology/contributor_count/`,
    trendingContentApi:`${process.env.REACT_APP_API_URL}/technology/trending_content/`,
    becomeContributorApi: `${process.env.REACT_APP_API_URL}/technology/upload_contributor/`,
    externalContent: `${process.env.REACT_APP_API_URL}/technology/fetch_external_content/`,
    activeInactiveUserStatus: `${process.env.REACT_APP_API_URL}/api/consumer_user_status/`,
    bannerImages : `${process.env.REACT_APP_API_URL}/technology/fetch_banner/`,
    consumerActionOnContributor :`${process.env.REACT_APP_API_URL}/technology/consumer_action_on_contributor/`,
    studyReports : `${process.env.REACT_APP_API_URL_CMS}/api/v1/list/knowledge-center/study-reports`,
    studyWhitepaper : `${process.env.REACT_APP_API_URL_CMS}/api/v1/list/knowledge-center/whitepapers`,
    BlogList : `${process.env.REACT_APP_API_URL_CMS}/api/v1/list/blogs`,
    BlogPage: `${process.env.REACT_APP_API_URL_CMS}/api/v1/blogs/content`,
    FocusArea : `${process.env.REACT_APP_API_URL_CMS}/api/v1/hp/focus-area`,
    TechAreaReports : `${process.env.REACT_APP_API_URL_CMS}/api/v1/technology-area/content/`,
    GetResource : `${process.env.REACT_APP_API_URL_CMS}/api/v1/hp/resources`,
    NotificationData: `${process.env.REACT_APP_API_URL}/technology/fetch_notifications/`,
    AISearchTestServer: `${process.env.REACT_APP_API_URL}/ai_search/test_server/`,
    AISearchChat: `${process.env.REACT_APP_API_URL}/ai_search/chat_api/`,
    get_webform_data: `${process.env.REACT_APP_API_URL}/technology/get_webform_data/`,
    submit_webform_detail : `${process.env.REACT_APP_API_URL_CMS}/api/v1/webform/submission`,
    claim_profile_detail: `${process.env.REACT_APP_API_URL_CMS}/api/v1/webform/claim-profile`,
    send_email_verify_domain : `${process.env.REACT_APP_API_URL}/api/send_email_verify_domain/`,
    verify_claim_otp : `${process.env.REACT_APP_API_URL}/api/verify_claim_otp/`,
    RagToken : `${process.env.REACT_APP_API_AI_SEARCH}/rag/ai-search-token`,
    AI_SEND_OTP : `${process.env.REACT_APP_API_AI_SEARCH}/rag/send_otp`,
    AI_VERIFY_OTP : `${process.env.REACT_APP_API_AI_SEARCH}/rag/verify_otp`,
    AI_CHAT: `${process.env.REACT_APP_API_AI_SEARCH}/rag/chat`, 
    // RagToken : `http://127.0.0.1:5000/ai-search-token`,
    // AI_SEND_OTP : `http://127.0.0.1:5000/send_otp`,
    // AI_VERIFY_OTP : `http://127.0.0.1:5000/verify_otp`,
    // AI_CHAT: `http://127.0.0.1:5000/chat`,
    claimed_entity : `${process.env.REACT_APP_API_URL}/api/get_claimed_entity_data/`
};

export function getGETConfig() {
    return {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
};

export function getAuthGETConfig(authToken) {
    return {
        method: 'GET',
        headers: {
            TCRAUTH: authToken,
            'Content-Type': 'application/json',
             
        }
    };
}
